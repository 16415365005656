import CatalogoService from '@/services/catalogo/catalogo.service';
import _ from 'lodash';

// initial state
const state = () => ({
    lista_catalogos: [],
})

// getters
const getters = {
    listaCatalogos: state => state.lista_catalogos,
};

// actions
const actions = {
    obtenerCatalogos({ commit, getters }, consulta) {
        if (getters.listaCatalogos.length > 0) {
            return false;
        }
        return CatalogoService.obtenerCatalogos(consulta)
            .then(
                response => {
                    commit('establecerCatalogos', response.data.result);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerCatalogos', []);
                    return Promise.reject(error);
                }
            )
    },

}

// mutations
const mutations = {
    establecerCatalogos: (state, catalogos) => {
        state.lista_catalogos = catalogos;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}