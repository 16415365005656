import axios from "axios";

const api_url = process.env.VUE_APP_API_URL;
const user = JSON.parse(localStorage.getItem('user'));

export default axios.create({ 
    baseURL: !user ? api_url : api_url + user.ruc,
    headers: {
        'XSRF-TOKEN':  user ? user.token : null 
    },
});
