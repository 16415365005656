import { Modal, notification } from 'ant-design-vue';

const notify = (type, message, description) => {
    notification[type]({ message, description });
}

const success = (title, content) => {
    Modal.success({ title, content });
};

const error = (title, content) => {
    Modal.error({ title, content });
};

const warning = (title, content) => {
    Modal.warning({ title, content });
};

const info = (title, content) => {
    Modal.info({ title, content });
};

export default { notify, success, error, warning, info };