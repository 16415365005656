import { createStore, createLogger } from 'vuex';

import auth from '@/store/auth/';
import usuario from '@/store/tenant/usuario/';
import bandeja_entrada from '@/store/tenant/bandeja_entrada/';
import envio_conformidad from '@/store/tenant/bandeja_entrada/envio_conformidad';
import catalogo from '@/store/catalogo/';

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    auth,
    usuario,
    bandeja_entrada,
    envio_conformidad,
    catalogo,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default store;

