import axios from '@/services/api/axios_tenant';

class BandejaEntradaService {
    obtenerDocumentos(consulta) {
        return axios.get(`/bandeja_entrada`)
            .then(response => {
                consulta.data = response.data.result;
                consulta.pag.total = response.data.result.length;

                return Promise.resolve(consulta);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    obtenerDocumentosAceptados(consulta) {
        return axios.get(`/bandeja_entrada/aceptados`)
            .then(response => {
                consulta.data = response.data.result;
                consulta.pag.total = response.data.result.length;

                return Promise.resolve(consulta);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    obtenerDocumentosEnvio(consulta) {
        return axios.get(`/bandeja_entrada/validos`)
            .then(response => {
                consulta.data = response.data.result;
                return Promise.resolve(consulta);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    aceptarEstadoComercial(data) {
        return axios.put(`/bandeja_entrada/${data.id}`, data)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    rechazarEstadoComercial(data) {
        return axios.put(`/bandeja_entrada/${data.id}`, data)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    EnviarDocumentos(data) {
        return axios.post(`/documentos_conformidad`, data)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    obtenerTickets(consulta) {
        return axios.get(`/documentos_conformidad`)
            .then(response => {
                consulta.data = response.data.result;
                consulta.pag.total = response.data.result.length;

                return Promise.resolve(consulta);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

}

export default new BandejaEntradaService();