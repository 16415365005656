import axios from '@/services/api/axios_tenant';

class AuthService {
    login(user) {
        return axios
            .post(`${user.ruc}/login`, { ...user })
            .then(response => {
                response.data.result.ruc = user.ruc;
                if (response.data.result) {
                    localStorage.setItem('user', JSON.stringify(response.data.result));
                }
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }
    logout() {
        localStorage.removeItem('user');
    }
    register(user) {
        return axios.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();