import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// iconos
import { FontAwesomeIcon } from './plugins/font-awesome'

// importar bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// alertas 
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = { confirmButtonColor: '#40a9ff', cancelButtonColor: '#ff7674' };

// importar ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import AntN from '@/plugins/ant-notification';

// formateador de fechas
import dayjs from 'dayjs'

// estilos personalizado
import './css/theme.css';
import './css/custom.css';

// configuracion de axios
import './services/api/axios.js';
import constantes from './config/constantes';

import alasql from '../node_modules/alasql/dist/alasql.min.js';

const app = createApp(App);

app.use(store)
    .use(router)
    .use(Antd)
    .use(VueSweetalert2, options)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

app.provide('dayJS', dayjs);

window.Swal = app.config.globalProperties.$swal;
window.dayJS = dayjs;
window.AntN = AntN;
window.AlaSQL = alasql;
window.constantes = constantes;
