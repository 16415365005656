import BandejaEntradaService from '@/services/tenant/bandeja_entrada.service';
import _ from 'lodash';

// initial state
const state = () => ({
    documentos: [],
    documentosPaginado: {},
    documento: null,

    isLoading: false,
    isDeleting: false,

    isCreating: false,
    createdData: null,
    isUpdating: false,
    updatedData: null,
    deletedData: null
})

// getters
const getters = {
    listaDocumentos: state => state.documentos,
    documentosPaginado: state => state.documentosPaginado,
    documento: state => state.documento,

    isLoading: state => state.isLoading,
    isDeleting: state => state.isDeleting,

    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,

    createdData: state => state.createdData,
    updatedData: state => state.updatedData,
    deletedData: state => state.deletedData
};

// actions
const actions = {
    obtenerDocumentos({ commit }, consulta) {
        commit('establecerIsLoading', true);
        return BandejaEntradaService.obtenerDocumentos(consulta)
            .then(
                response => {
                    commit('establecerDocumentos', response.data);
                    commit('establecerDocumentosPaginado', response);
                    commit('establecerIsLoading', false);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerDocumentosPaginado', consulta);
                    commit('establecerIsLoading', false);
                    return Promise.reject(error);
                }
            )
    },

    obtenerDocumentosAceptados({ commit }, consulta) {
        commit('establecerIsLoading', true);
        return BandejaEntradaService.obtenerDocumentosAceptados(consulta)
            .then(
                response => {
                    commit('establecerDocumentos', response.data);
                    commit('establecerDocumentosPaginado', response);
                    commit('establecerIsLoading', false);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerDocumentosPaginado', consulta);
                    commit('establecerIsLoading', false);
                    return Promise.reject(error);
                }
            )
    },

    obtenerDocumentosEnvio({ commit }, consulta) {
        commit('establecerIsLoading', true);
        return BandejaEntradaService.obtenerDocumentosEnvio(consulta)
            .then(
                response => {
                    commit('establecerIsLoading', false);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerIsLoading', false);
                    return Promise.reject(error);
                }
            )
    },

    async obtenerDocumentosPaginado({ commit, getters }, consulta) {

        let { data, pag, sorter, filters } = consulta
        data = getters.listaDocumentos;

        let condiciones = '';

        if (filters) {

            if (filters.fecha_inicial && filters.fecha_final) {
                let fecha_inicial = dayJS(filters.fecha_inicial).format('YYYY-MM-DD');
                let fecha_final = dayJS(filters.fecha_final).format('YYYY-MM-DD');
                
                condiciones += ` AND fecha BETWEEN '${fecha_inicial}' AND '${fecha_final}'`;
            }

            if (filters.texto_documento) {
                condiciones += ` AND (serie LIKE '%${filters.texto_documento}%' OR correlativo LIKE '%${filters.texto_documento}%')`
            }

            if (filters.texto_proveedor) {
                condiciones += ` AND (proveedor_id LIKE '%${filters.texto_proveedor}%')`
            }

            if (filters.tipo_documento_id && filters.tipo_documento_id.length > 0) {
                let tipo_documento_id = JSON.stringify(filters.tipo_documento_id).replace('[','(').replace(']',')')
                
                condiciones += ` AND tipo_documento_id IN ${tipo_documento_id}`
            }

            if (filters.tipo_estado_id && filters.tipo_estado_id.length > 0) {
                let tipo_estado_id = JSON.stringify(filters.tipo_estado_id).replace('[','(').replace(']',')')
                
                condiciones += ` AND (estado_comercial IN ${tipo_estado_id})`
            }
        }

        if (sorter.order) {
            condiciones += `ORDER BY ${sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }

        data = AlaSQL(`SELECT * FROM ? WHERE id NOT NULL ${condiciones}`, [data]);
        
        commit('establecerDocumentosPaginado', { data, pag, sorter, filters });
    },

    async aceptarEstadoComercial({ commit }, data) {
        commit('establecerIsLoading', true);
        
        return BandejaEntradaService.aceptarEstadoComercial(data)
            .then(
                response => {
                    commit('acutalizarEstadoComerial', data);
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    async rechazarEstadoComercial({ commit }, data) {
        commit('establecerIsDeleting', true);

        return BandejaEntradaService.rechazarEstadoComercial(data)
        .then(
            response => {
                commit('acutalizarEstadoComerial', data);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    
    async storeProduct({ commit }, product) {
        commit('setProductIsCreating', true);
        await axios.post(`${process.env.VUE_APP_API_URL}products`, product)
            .then(res => {
                commit('saveNewProducts', res.data.data);
                commit('setProductIsCreating', false);
            }).catch(err => {
                console.log('error', err);
                commit('setProductIsCreating', false);
            });
    },

    async updateProduct({ commit }, product) {
        commit('setProductIsUpdating', true);
        await axios.post(`${process.env.VUE_APP_API_URL}products/${product.id}?_method=PUT`, product)
            .then(res => {
                commit('saveUpdatedProduct', res.data.data);
                commit('setProductIsUpdating', false);
            }).catch(err => {
                console.log('error', err);
                commit('setProductIsUpdating', false);
            });
    },


    async deleteProduct({ commit }, id) {
        commit('establecerIsDeleting', true);
        await axios.delete(`${process.env.VUE_APP_API_URL}products/${id}`)
            .then(res => {
                commit('setDeleteProduct', res.data.data.id);
                commit('establecerIsDeleting', false);
            }).catch(err => {
                console.log('error', err);
                commit('establecerIsDeleting', false);
            });
    },

    updateProductInput({ commit }, e) {
        commit('setProductDetailInput', e);
    }
}

// mutations
const mutations = {
    establecerDocumentos: (state, documentos) => {
        state.documentos = documentos;
    },

    establecerDocumentosPaginado: (state, documentosPaginado) => {
        state.documentosPaginado = documentosPaginado;
    },

    acutalizarEstadoComerial: (state, documento) => {
        state.documentosPaginado.data.map(item => {
            if (item.id == documento.id){
                debugger
                item.estado_comercial = documento.estado_comercial
                item.data_estado_comercial = documento.data_estado_comercial
            }
            return item;
        })
    },

    setProductDetail: (state, product) => {
        state.product = product
    },

    setDeleteProduct: (state, id) => {
        state.documentosPaginado.data.filter(x => x.id !== id);
    },

    setProductDetailInput: (state, e) => {
        let product = state.product;
        product[e.target.name] = e.target.value;
        state.product = product
    },

    saveNewProducts: (state, product) => {
        state.products.unshift(product)
        state.createdData = product;
    },

    saveUpdatedProduct: (state, product) => {
        state.products.unshift(product)
        state.updatedData = product;
    },

    establecerIsLoading(state, isLoading) {
        state.isLoading = isLoading
    },

    setProductIsCreating(state, isCreating) {
        state.isCreating = isCreating
    },

    setProductIsUpdating(state, isUpdating) {
        state.isUpdating = isUpdating
    },

    establecerIsDeleting(state, isDeleting) {
        state.isDeleting = isDeleting
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}