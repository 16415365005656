import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + 'cliente/usuarios';

// initial state
const state = () => ({
    usuarios: [],
    usuarioPaginado: null,
    usuario: null,
    isLoading: false,
    isCreating: false,
    createdData: null,
    isUpdating: false,
    updatedData: null,
    isDeleting: false,
    deletedData: null
})

// getters
const getters = {
    listaUsuarios: state => state.usuarios,
    usuarioPaginado: state => state.usuarioPaginado,
    usuario: state => state.usuario,

    isLoading: state => state.isLoading,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    isDeleting: state => state.isDeleting,
    
    createdData: state => state.createdData,
    updatedData: state => state.updatedData,
    deletedData: state => state.deletedData
};

// actions
const actions = {
    async ObtenerTodosUsuarios({ commit }, consulta = null) {
        let pagina = '';
        let buscar = '';

        if (consulta !== null) {
            pagina = consulta.pagina;
            buscar = consulta.buscar;
        }

        commit('establecerIsLoading', true);
        
        // let url = `${API_URL}/buscar?search=${buscar}&page=${pagina}`;
        let url = API_URL;

        await axios.get(url)
            .then(res => {
                const usuarios = res.data.result;
                commit('establecerUsuarios', usuarios);
                const paginacion = {
                    total: usuarios.length,
                    current: 1,
                    pageSize: 10,
                }
                res.data.paginacion = paginacion;
                commit('establecerUsuariosPaginados', res.data);
                commit('establecerIsLoading', false);
            }).catch(err => {
                console.log('error', err);
                commit('establecerIsLoading', false);
            });
    },

    async ObtenerUsuarioDetallado({ commit }, id) {
        commit('establecerIsLoading', true);
        await axios.get(`${process.env.VUE_APP_API_URL}products/${id}`)
            .then(res => {
                commit('setProductDetail', res.data.data);
                commit('establecerIsLoading', false);
            }).catch(err => {
                console.log('error', err);
                commit('establecerIsLoading', false);
            });
    },

    async storeProduct({ commit }, product) {
        commit('setProductIsCreating', true);
        await axios.post(`${process.env.VUE_APP_API_URL}products`, product)
            .then(res => {
                commit('saveNewProducts', res.data.data);
                commit('setProductIsCreating', false);
            }).catch(err => {
                console.log('error', err);
                commit('setProductIsCreating', false);
            });
    },

    async updateProduct({ commit }, product) {
        commit('setProductIsUpdating', true);
        commit('setProductIsUpdating', true);
        await axios.post(`${process.env.VUE_APP_API_URL}products/${product.id}?_method=PUT`, product)
            .then(res => {
                commit('saveUpdatedProduct', res.data.data);
                commit('setProductIsUpdating', false);
            }).catch(err => {
                console.log('error', err);
                commit('setProductIsUpdating', false);
            });
    },


    async deleteProduct({ commit }, id) {
        commit('setProductIsDeleting', true);
        await axios.delete(`${process.env.VUE_APP_API_URL}products/${id}`)
            .then(res => {
                commit('setDeleteProduct', res.data.data.id);
                commit('setProductIsDeleting', false);
            }).catch(err => {
                console.log('error', err);
                commit('setProductIsDeleting', false);
            });
    },

    updateProductInput({ commit }, e) {
        commit('setProductDetailInput', e);
    }
}

// mutations
const mutations = {
    establecerUsuarios: (state, usuarios) => {
        state.usuarios = usuarios
    },

    establecerUsuariosPaginados: (state, usuarioPaginado) => {
        state.usuarioPaginado = usuarioPaginado
    },

    setProductDetail: (state, product) => {
        state.product = product
    },

    setDeleteProduct: (state, id) => {
        state.usuarioPaginado.data.filter(x => x.id !== id);
    },

    setProductDetailInput: (state, e) => {
        let product = state.product;
        product[e.target.name] = e.target.value;
        state.product = product
    },

    saveNewProducts: (state, product) => {
        state.products.unshift(product)
        state.createdData = product;
    },

    saveUpdatedProduct: (state, product) => {
        state.products.unshift(product)
        state.updatedData = product;
    },

    establecerIsLoading(state, isLoading) {
        state.isLoading = isLoading
    },

    setProductIsCreating(state, isCreating) {
        state.isCreating = isCreating
    },

    setProductIsUpdating(state, isUpdating) {
        state.isUpdating = isUpdating
    },

    setProductIsDeleting(state, isDeleting) {
        state.isDeleting = isDeleting
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}