import axios from "axios";

const user = JSON.parse(localStorage.getItem('user'));
// default configurations

// Add a request interceptor
axios.interceptors.request.use(
    // config => {
    //     if (user && user.token) {
    //         config.headers['Authorization'] = 'Bearer ' + user.token;
    //     }
    //     return config;
    // },
    error => {
        Promise.reject(error)
    });

//Add a response interceptor
axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    return Promise.reject(error);
});