import BandejaEntradaService from '@/services/tenant/bandeja_entrada.service';
import _ from 'lodash';

// initial state
const state = () => ({
    tickets: [],
    ticketsPaginado: {},
    ticket: null,

    isLoading: false,
    isSubmit: false,

    isCreating: false,
    createdData: null,
    isUpdating: false,
    updatedData: null,
    deletedData: null
})

// getters
const getters = {
    listaTickets: state => state.tickets,
    ticketsPaginado: state => state.ticketsPaginado,
    ticket: state => state.ticket,

    isLoading: state => state.isLoading,
    isSubmit: state => state.isSubmit,

    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    createdData: state => state.createdData,
    updatedData: state => state.updatedData,
    deletedData: state => state.deletedData
};

// actions
const actions = {
    obtenerTickets({ commit }, consulta) {
        commit('establecerIsLoading', true);
        return BandejaEntradaService.obtenerTickets(consulta)
            .then(
                response => {
                    commit('establecerTickets', response.data);
                    commit('establecerTicketsPaginado', response);
                    commit('establecerIsLoading', false);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerTicketsPaginado', consulta);
                    commit('establecerIsLoading', false);
                    return Promise.reject(error);
                }
            )
    },
    enviarDocumentos({ commit }, data) {
        commit('establecerIsSubmit', true);
        debugger
        return BandejaEntradaService.EnviarDocumentos(data)
            .then(
                response => {
                    commit('establecerIsSubmit', false);
                    commit('agregarTicket', response.data.result);
                    return Promise.resolve(response);
                },
                error => {
                    commit('establecerIsSubmit', false);
                    return Promise.reject(error);
                }
            )
    },

    async obtenerTicketsPaginado({ commit, getters }, consulta) {
        let { data, sorter } = consulta
        data = getters.listaDocumentos;

        let condiciones = '';

        if (filters) {

            if (filters.fecha_inicial && filters.fecha_final) {
                let fecha_inicial = dayJS(filters.fecha_inicial).format('YYYY-MM-DD');
                let fecha_final = dayJS(filters.fecha_final).format('YYYY-MM-DD');
                
                condiciones += ` AND fecha BETWEEN '${fecha_inicial}' AND '${fecha_final}'`;
            }

            if (filters.texto_documento) {
                condiciones += ` AND (serie LIKE '%${filters.texto_documento}%' OR correlativo LIKE '%${filters.texto_documento}%')`
            }

            if (filters.texto_proveedor) {
                condiciones += ` AND (proveedor_id LIKE '%${filters.texto_proveedor}%')`
            }

            if (filters.tipos_comprobante_id && filters.tipos_comprobante_id.length > 0) {
                let tipos_comprobante_id = JSON.stringify(filters.tipos_comprobante_id).replace('[','(').replace(']',')')
                
                condiciones += ` AND tipo_documento_id IN ${tipos_comprobante_id}`
            }

            if (filters.estados_comprobante_id && filters.estados_comprobante_id.length > 0) {
                let estados_comprobante_id = JSON.stringify(filters.estados_comprobante_id).replace('[','(').replace(']',')')
                
                condiciones += ` AND (descripcion_cpe IN ${estados_comprobante_id})`
            }
        }

        if (sorter.order) {
            condiciones += `ORDER BY ${sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }

        data = AlaSQL(`SELECT * FROM ? WHERE id NOT NULL ${condiciones}`, [data]);
        
        commit('establecerticketsPaginado', { ...consulta, data, sorter, });
    },
    
}

// mutations
const mutations = {
    establecerTickets: (state, tickets) => {
        state.tickets = tickets;
    },

    establecerTicketsPaginado: (state, ticketsPaginado) => {
        state.ticketsPaginado = ticketsPaginado;
    },

    establecerIsLoading(state, isLoading) {
        state.isLoading = isLoading
    },

    establecerIsSubmit(state, isSubmit) {
        state.isSubmit = isSubmit
    },

    agregarTicket(state, data) {
        state.tickets.unshift(data);
        state.ticketsPaginado.data.unshift(data);
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}