import { createRouter, createWebHistory } from "vue-router";
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout(layout => {
    return import('@/views/layouts/' + layout + '.vue')
})

let routes = [
    {
        path: '/error',
        component: () => import('@/views/error/404.vue'),
    },
    {
        path: '/prueba',
        component: () => import('@/views/pruebas/'),
    },
    {
        path: "/",
        component: RouterLayout,
        children: [
            {
                path: '/principal',
                name: 'Principal',
                component: () => import('@/views/tenant/principal/')
            },
            {
                path: "/usuarios",
                name: "Usuarios",
                component: () => import('@/views/tenant/usuario/'),
            },
            {
                path: "/bandeja_entrada/listado",
                name: "BandejaEntrada",
                component: () => import('@/views/tenant/bandeja_entrada/Listado.vue'),
            },
            {
                path: "/bandeja_entrada/aceptar_rechazar",
                name: "AceptarRechazar",
                component: () => import('@/views/tenant/bandeja_entrada/AceptarRechazar.vue'),
            },
            {
                path: "/bandeja_entrada/envio_sunat",
                name: "EnvioSunat",
                component: () => import('@/views/tenant/bandeja_entrada/EnvioSunat.vue'),
            },
            {
                path: "/login",
                name: "Login",
                component: () => import('@/views/auth/Login.vue'),
            },
            {
                path: "/register",
                name: "Register",
                component: () => import('@/views/auth/Register.vue'),
            },
        ],
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const publicPages = ['Login', 'Register'];
    
    const authRequired = !publicPages.includes(to.name);
    const user = localStorage.getItem('user');

    // cuando un usuario logueado quiere ingresar al login 
    if (!authRequired && user && to.name == 'Login') {
        next('/principal');
    }

    // cuando un usuario no logueado quiere ingresar al sistema
    if (authRequired && !user) {
        next('/login');
    } else {
        next();
    }
});

export default router;
