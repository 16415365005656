import AuthService from '@/services/auth/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

// initial state
const state = {
    loggedIn: user ? true : false,
    user: user ? user : null
}

// getters
const getters = {
    user: state => state.user,
}

// actions
const actions = {
    login({ commit }, user) {
        return AuthService.login(user).then(
            user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    },
    register({ commit }, user) {
        return AuthService.register(user).then(
            response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            error => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    }
}

// mutations
const mutations = {
    loginSuccess(state, user) {
        state.loggedIn = true;
        state.user = user;
    },
    loginFailure(state) {
        state.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.loggedIn = false;
    },
    registerFailure(state) {
        state.loggedIn = false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}